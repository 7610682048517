$white: #ffffff;

$primary-050: #f2f2f6;
$primary-100: #dfe0e9;
$primary-200: #a0a6bd;
$primary-300: #8389a9;
$primary-400: #4b5480;
$primary-500-base: #1c2964;
$primary-600: #17214e;
$primary-700: #151d42;
$primary-800: #121937;
$primary-900: #10152c;

$secondary-050: #ffe0e7;
$secondary-100: #ffc1cf;
$secondary-200: #ffa3b8;
$secondary-300: #ff6b8c;
$secondary-400: #ff5177;
$secondary-500-base: #ff2458;
$secondary-600: #da1d44;
$secondary-700: #951730;
$secondary-800: #55101e;
$secondary-900: #1d090e;

$tertiary-050: #ddf9fc;
$tertiary-100: #bcf5f7;
$tertiary-200: #9ceff3;
$tertiary-300: #7de9f0;
$tertiary-400: #44dfe8;
$tertiary-500-base: #11d9e5;
$tertiary-600: #11b6c1;
$tertiary-700: #0e7d84;
$tertiary-800: #0d6267;
$tertiary-900: #082f32;
 
$info-050: #eef8ff;
$info-100: #dbf0fe;
$info-200: #b1e2fd;
$info-300: #72cdfc;
$info-400: #31b5f7;
$info-500: #109be6;
$info-600: #0679c0;
$info-700: #075e97;
$info-800: #0b4f7a;
$info-900: #0f4163;

$warning-050: #fffae8;
$warning-100: #fef1c0;
$warning-200: #fde27f;
$warning-300: #fccd44;
$warning-400: #fab721;
$warning-500: #f3940e;
$warning-600: #d46c0a;
$warning-700: #ab490d;
$warning-800: #873810;
$warning-900: #6d2f11;

$success-050: #e9fdf3;
$success-100: #cbf9e1;
$success-200: #9df1ca;
$success-300: #63e3ae;
$success-400: #2ecd8e;
$success-500: #11b076;
$success-600: #098b5e;
$success-700: #086d4d;
$success-800: #0a543d;
$success-900: #0a4534;

$danger-050: #fef0f0;
$danger-100: #fedede;
$danger-200: #fec3c3;
$danger-300: #fc9b9b;
$danger-400: #f76666;
$danger-500: #ed3c3c;
$danger-600: #d72222;
$danger-700: #b01a1a;
$danger-800: #8e1a1a;
$danger-900: #741b1b;

$grey-warm-050: #f7f9fc;
$grey-warm-100: #eff3f8;
$grey-warm-200: #dee5ee;
$grey-warm-300: #c4cfdd;
$grey-warm-400: #8999af;
$grey-warm-500: #596980;
$grey-warm-600: #3e4b5e;
$grey-warm-700: #2d394b;
$grey-warm-800: #1c2534;
$grey-warm-900: #111725;

$grey-violet-050: #fbfaff;
$grey-violet-100: #f7f6ff;
$grey-violet-200: #f6f4ff;
$grey-violet-300: #f0edff;
$grey-violet-400: #ccc8da;
$grey-violet-500: #b4b2bf;
$grey-violet-600: #96949f;
$grey-violet-700: #787680;
$grey-violet-800: #504f55;
$grey-violet-900: #3c3b40;

$sup-purple-050: #F5EDFF;
$sup-purple-100: #E6D3FF;
$sup-purple-500-base: #8424FF;
