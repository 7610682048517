@use 'colors.scss';
@use 'typography';
@use 'variables';

.ant-modal-root {
  .ant-modal-content {
    border-radius: 8px;
  }

  .ant-modal-header {
      border-bottom: 0;
      border-radius: 8px;

      .ant-modal-title {
        font-weight: 700;
        color: colors.$grey-warm-500;
        font-family: 'Cera Pro', serif;
      }
    }

    .ant-form-item label {
      font-weight: 700;
      color: colors.$grey-warm-600;
      font-family: 'Cera Pro', serif;
    }

    .ant-btn-ghost {
      margin-right: 8px;
      border: 1px solid colors.$secondary-500-base !important;
    }
}

.ant-modal-body {
  padding-top: 24px;
}

.ant-drawer-body {
  // RIGHT CYRCLE
  /* ff 3.6+ */
  background: -moz-radial-gradient(circle at 84% 7%, #E7FBFF 5%, #E5FBFF 7%, #F0EAFF 22%, #F2EEFF 39%, #FFFFFF 54%, #FFFFFF 58%);
  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(circle at 84% 7%, #E7FBFF 5%, #E5FBFF 7%, #F0EAFF 22%, #F2EEFF 39%, #FFFFFF 54%, #FFFFFF 58%);
  /* opera 11.10+ */
  background: -o-radial-gradient(circle at 84% 7%, #E7FBFF 5%, #E5FBFF 7%, #F0EAFF 22%, #F2EEFF 39%, #FFFFFF 54%, #FFFFFF 58%);
  /* ie 10+ */
  background: -ms-radial-gradient(circle at 84% 7%, #E7FBFF 5%, #E5FBFF 7%, #F0EAFF 22%, #F2EEFF 39%, #FFFFFF 54%, #FFFFFF 58%);
  /* global 92%+ browsers support */
  background: radial-gradient(circle at 84% 7%, #E7FBFF 5%, #E5FBFF 7%, #F0EAFF 22%, #F2EEFF 39%, #FFFFFF 54%, #FFFFFF 58%);
  padding: 48px 24px 48px 24px;
  display: flex;
  gap: 24px;
}
