@use 'variables';

@mixin heading($font-size, $line-height) {
  font-family: Cera Pro, serif;
  font-style: normal;
  font-weight: bold;
  font-size: $font-size;
  line-height: $line-height;
  margin-top: 0;
}

@mixin body-text($font-size, $line-height) {
  font-family: Source Sans Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: $font-size;
  line-height: $line-height;
}

@mixin mobile {
  @media (min-width: #{variables.$screen-xs}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{variables.$screen-sm}) {
    @content;
  }
}

@mixin desktop-medium {
  @media (min-width: #{variables.$screen-md}) {
    @content;
  }
}

@mixin desktop-large {
  @media (min-width: #{variables.$screen-lg}) {
    @content;
  }
}

@mixin desktop-xlarge {
  @media (min-width: #{variables.$screen-xl}) {
    @content;
  }
}