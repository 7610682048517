@use 'colors.scss';
@use 'variables';

.antd-overwrite {
  .ant-collapse-content-box {
    background-color: colors.$white;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .ant-collapse > .ant-collapse-item {
    border: none !important;
  }

  .ant-collapse-item:last-child > .ant-collapse-content {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .ant-collapse-header {
    display: flex;
    justify-content: space-between;
    padding: 0 !important;

    &::before,
    &::after {
      display: none !important;
    }
  }

  .ant-collapse-content {
    border: none !important;
  }

  .ant-collapse-item-active {
    .arrow {
      transition: variables.$animate-2s;
      transform: rotate(90deg);
    }
  }
}
