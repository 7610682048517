@use 'colors.scss';
@use './typography';

.antd-overwrite {
  .ant-radio-wrapper {
    color: colors.$grey-warm-600 !important;
    @extend .main-body-text;

    .ant-radio {
      background-color: colors.$white;

      .ant-radio-inner {
        border-radius: 20px;
        border: 1px solid colors.$grey-warm-500;
      }

      &:hover {
        .ant-radio-inner {
          border: 1px solid colors.$secondary-500-base;
        }
      }

      &:focus, &:active {
        .ant-radio-inner {
          border: 1px solid colors.$secondary-500-base;
          box-shadow: 0 0 0 2px colors.$secondary-050;
        }
      }

      &.ant-radio-checked {
        .ant-radio-inner {
          background-color: colors.$white;
          border: 1px solid colors.$secondary-500-base;

          &:after {
            background-color: colors.$secondary-500-base;
            border: 1px solid colors.$secondary-500-base;
          }
        }

        &:after {
          border: 1px solid colors.$secondary-500-base;
          border-radius: 20px;
        }
      }

      &.ant-radio-disabled {
        .ant-radio-inner {
          background-color: colors.$grey-warm-100;
          border: 1px solid colors.$grey-warm-200;
          border-radius: 20px;
          box-shadow: none;
        }
      }
    }
  }
}
