@use 'colors';
@use 'variables';
@use 'typography';

.antd-overwrite {
  .ant-picker {
    width: 100%;
    padding: variables.$padding-20 variables.$padding-24;
    border-radius: 8px !important;
    border: 1px solid colors.$grey-violet-200 !important;
    background-color: colors.$grey-violet-050 !important;
    color: colors.$grey-warm-600 !important;

    &.ant-picker-small {
      padding: variables.$padding-12 variables.$padding-16;

      .ant-picker-input {
        & > input {
          @extend .small-body-text;
        }
      }
    }

    &.ant-picker-large {
      padding: variables.$padding-24 variables.$padding-32;

      .ant-picker-input {
        & > input {
          @extend .large-body-text;
        }
      }
    }

    &.ant-picker-focused {
      background-color: colors.$white;
      box-shadow: 0 0 0 2px colors.$primary-500-base;
      border: 1px solid colors.$grey-violet-200;
      color: colors.$primary-500-base;
    }

    @extend .main-body-text;

    &:hover {
      box-shadow: 0 0 0 2px colors.$primary-300;
      border: 1px solid colors.$grey-violet-200;
    }

    &:active {
      background-color: colors.$white;
      box-shadow: 0 0 0 2px colors.$primary-500-base;
      border: 1px solid colors.$grey-violet-200;
      color: colors.$primary-500-base;
    }

    .ant-picker-input {
      &:focus,
      &:active {
        background-color: colors.$white;
        box-shadow: 0 0 0 2px colors.$primary-500-base;
        border: 1px solid colors.$grey-violet-200;
        color: colors.$primary-500-base;
      }

      .ant-picker-suffix {
        color: colors.$grey-warm-400;
      }

      & > input {
        color: colors.$grey-warm-600;
        @extend .main-body-text;

        &::placeholder {
          color: colors.$grey-warm-400;
          opacity: 1;
        }
      }
    }
  }

  .ant-form-item-has-error {
    .ant-picker {
      border: 1px solid colors.$danger-400 !important;

      &:hover {
        box-shadow: 0 0 0 2px rgba(colors.$danger-400, 0.6) !important;
      }

      &-focused {
        box-shadow: 0 0 0 2px rgba(colors.$danger-400, 0.6) !important;
      }
    }
  }
}