@use 'mixins';

@font-face {
  font-family: Cera Pro;
  font-style: normal;
  font-weight: bold;
  src:
    url('../public/fonts/Cera-Pro-Bold.otf') format('opentype');
  font-display: swap;
}

.h1 {
  @include mixins.heading(50px, 63px);
}
.h2 {
  @include mixins.heading(38px, 48px);
}
.h3 {
  @include mixins.heading(28px, 35px);
}
.h4 {
  @include mixins.heading(21px, 26px);
}
.h5 {
  @include mixins.heading(16px, 22px);
}
.h6 {
  @include mixins.heading(12px, 15px);
}

.uppercase {
  text-transform: uppercase;
}

.large-body-text {
  @include mixins.body-text(16px, 20px);
}
.main-body-text {
  @include mixins.body-text(14px, 18px);
}
.small-body-text {
  @include mixins.body-text(12px, 15px);
}
.secondary-body-text {
  @include mixins.body-text(10px, 13px);
}
.small-caps-regular {
  @include mixins.body-text(12px, 10px);
  letter-spacing: 0.04em;
}
.small-caps-bold {
  @include mixins.heading(12px, 10px);
}
