@use '../../../styles/variables.scss';
@use '../../../styles/colors.scss';
@use '../../../styles/typography.scss';
@use '../../../styles/mixins.scss';

.sider {
  display: none;
  width: 0 !important;
  max-width: 0 !important;
  @include mixins.desktop-large {
    border-right: 1px solid colors.$grey-violet-300;
    display: block;
    overflow: auto;
    height: 100vh;
    position: fixed !important;
    left: 0;
    width: 250px !important;
    max-width: 250px !important;
  }
}

.main {
  margin: 0;
  @include mixins.desktop-large {
    margin: 2% 8%;
    background: colors.$grey-violet-050;
  }
}

.mainColor {
  background: colors.$grey-violet-050;
}

.noSider {
  margin-left: 0 !important;
}
