@use '../../../../../styles/variables.scss';
@use '../../../../../styles/colors.scss';

.modalButtons {
  display: flex;
  gap: 8px;
}

.wrapUploadText {
  margin-top: 4px;

  .uploadText {
    color: colors.$grey-warm-300;
  }
}