@use '../../styles/variables.scss';
@use '../../styles/colors.scss';
@use '../../styles/typography.scss';
@use '../../styles/mixins.scss';

.compWrap {
  position: sticky;
  bottom: 0;
  z-index: 100;
  width: 100%;
  background: colors.$white;
  border-top: 1px solid colors.$grey-violet-300;
  padding: variables.$padding-12 0;
  box-shadow: 0 0 16px -4px rgba(131, 137, 169, 0.2);
  @include mixins.desktop-large {
    display: none;
  }
}

// custom drawer css
.drawer {
  bottom: 0;

  display: block;
  @include mixins.desktop-large {
    display: none;
  }
}

.openSubmenu {
  bottom: variables.$bottom-height-mobile !important;
}

.submenuItem {
  cursor: pointer;
  color: colors.$grey-warm-500;
  padding: variables.$padding-12 variables.$padding-16;
}



.bottom-bar-container {
  &:global {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &::before {
      display: none;
    }

    &::after {
      display: none;
    }

    @include mixins.desktop-large {
      display: none;
    }

    &.ant-menu-horizontal {
      border-bottom: none;
      padding: 0 24px;

      .ant-menu-item .ant-menu-item-icon + span {
        margin-left: 0;
      }

      .ant-menu-item {
        & > div {
          margin-bottom: variables.$margin-4;
          padding: variables.$padding-4;
        }

        padding: 0;
        border-bottom: none !important;

        &:hover {
          color: colors.$grey-violet-700;
        }

        &.ant-menu-item-selected {
          & > div {
            border: 1px solid colors.$secondary-500-base;
            padding: variables.$padding-4;
            width: 24px;
            height: 24px;
          }

          border-bottom: none !important;
          color: colors.$grey-warm-700;

          .ant-menu-item-icon {
            & path {
              fill: colors.$secondary-500-base;
            }
          }

          &::after {
            border-bottom: none;
          }
        }

        margin: 0;
        color: colors.$grey-warm-400;
        @include mixins.heading(12px, 15px);
        display: flex;
        flex-direction: column;
        align-items: center;

        .ant-menu-item-icon {
          margin-bottom: variables.$margin-4;
          height: 24px;
          width: 24px;
          flex-shrink: 0;
          margin-right: 0;
          margin-left: 0;

          & path {
            fill: colors.$grey-warm-500;
          }
        }
      }
    }
  }
}
