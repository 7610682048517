@use '../../../../styles/variables.scss';
@use '../../../../styles/colors.scss';
@use '../../../../styles/typography.scss';
@use '../../../../styles/mixins.scss';

.compWrap {
  position: relative;
  background-color: colors.$white;
  z-index: 4;

  :global {
    .ant-modal-title {
      font-family: 'Cera Pro', serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      text-align: justify;
      text-transform: uppercase;
      color: #3E4B5E;
    }

    .ant-dropdown-menu {
      border-radius: 8px;
    }

    .menu-container {
      padding: 0 !important;
      margin-bottom: 0 !important;
      border-bottom: none;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &.ant-menu {
        margin-bottom: 0 !important;

        .ant-menu-item {
          margin-bottom: 0 !important;
          padding: 16px !important;
          font-family: 'Cera Pro', serif !important;
          font-style: normal !important;
          font-weight: 700 !important;
          font-size: 16px !important;
          line-height: 15px !important;
          margin-right: 4px !important;
          border-top: 1px solid white;
          border-left: 1px solid white;
          border-right: 1px solid white;
          border-radius: 8px 8px 0 0 !important;
          transition: none;
          color: colors.$grey-violet-700;
          display: flex;
          align-items: center;

          &:after {
            border-bottom: 0;
          }

          .ant-menu-item-icon {
            height: 18px !important;
            width: 18px !important;

            path {
              fill: colors.$grey-violet-700 !important;
            }
          }

          &.ant-menu-item-selected {
            border-radius: 8px 8px 0 0 !important;
            background-color: colors.$grey-violet-050;
            border-top: 1px solid colors.$primary-100;
            border-left: 1px solid colors.$primary-100;
            border-right: 1px solid colors.$primary-100;
            color: #ff2458 !important;

            .ant-menu-item-icon path {
              fill: #ff2458 !important;
            }

            &:after {
              border-bottom: 0 !important;
            }
          }

          &:hover {
            border-radius: 8px 8px 0 0;
            background: colors.$grey-violet-100 !important;
            border-top: 1px solid colors.$primary-100;
            border-left: 1px solid colors.$primary-100;
            border-right: 1px solid colors.$primary-100;

            &:after {
              border-bottom: 0 !important;
            }
          }
        }
        .ant-menu-overflow-item:hover {
          color: colors.$grey-warm-500;
        }
      }
    }
  }
}

.containerDesktopWrap {
  display: none;
  @include mixins.desktop-large {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .mainMenuWrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 8px;
    padding: 0 8%;

    .wrapMenuButton {
      width: 300px;

      .newPositionButton {
        float: right;
      }
    }
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @include mixins.desktop-large {
    display: none;
  }
}

.logos {
  display: flex;
  align-items: center;
  width: 200px;
}

.searchWrapper {
  max-width: variables.$screen-md;
  width: 100%;
  padding: 0 variables.$padding-24;
}

.mobile {
  height: variables.$header-height-mobile-nav;
  padding: variables.$padding-20;

  @include mixins.desktop-large {
    display: none;
  }
}

.desktop {
  height: variables.$header-height-desktop;
  display: none;
  @include mixins.desktop-large {
    display: flex;
    align-items: center;
    margin-top: 8px;
    padding: 0 8%;
  }
}

.logo {
  height: 24px;
  cursor: pointer;

  @include mixins.desktop-large {
    display: none;
  }
}

.logo_border {
  height: 24px;
  cursor: pointer;

  @include mixins.desktop-large {
    display: none;
  }
}

.logoDesktop_hrprior {
  display: none;
  height: 20px;
  cursor: pointer;
  @include mixins.desktop-large {
    padding-right: 8px;
    display: block;
    border-right: 1px solid colors.$primary-100;
    max-width: 500px;
  }
}

.logoDesktop {
  cursor: pointer;
  display: none;
  height: 40px;

  @include mixins.desktop-large {
    display: block;
  }
}

.logoDesktop_user {
  cursor: pointer;
  display: none;
  height: 24px;
  margin-left: 10px;
  @include mixins.desktop-large {
    display: block;
  }
}

.searchIcon {
  height: 16px;
  cursor: pointer;

  path {
    fill: colors.$grey-warm-600;
  }
}

.auth {
  display: none;
  @include mixins.desktop-large {
    display: flex;
    align-items: center;
  }
}

.line {
  display: none;
  @include mixins.desktop-large {
    margin: 0 variables.$margin-16 0 variables.$margin-8;
    width: 1px;
    height: 24px;
    background-color: colors.$grey-violet-200;
  }
}

.actions {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.button {
  width: 100%;
}

.buttonMarginBottom12 {
  margin-bottom: variables.$margin-12;
}

.loginButton {
  margin-right: variables.$margin-12;
}


.profileMenus {
  display: flex;

  .menuPadding {
    padding: 12px 16px
  }

  .gearButton {
    padding: 8px;
    width: 40px;
    height: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    border: 1px solid #F0EDFF;
    border-radius: 90px;
    background: #FBFAFF;
    margin-right: 16px;

    &:hover {
      background-color: colors.$grey-violet-200;
      cursor: pointer;
    }
  }

  .avatarButton {
    padding: 4px 4px 4px 4px;
    height: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: center;
    border: 1px solid #F0EDFF;
    border-radius: 90px;
    background: #FBFAFF;

    &:hover {
      background-color: colors.$grey-violet-200;
      cursor: pointer;
    }
  }
}