@use 'colors.scss';
@use 'variables';

.antd-overwrite {
  .ant-tabs-ink-bar {
    background: transparent !important;
  }
  .ant-tabs-tab {
    font-size: 16px;
    padding: variables.$padding-12 0 !important;
  }

  .ant-tabs-top > .ant-tabs-nav {
    margin: 0;
  }

  .ant-tabs-tab-btn {
    padding: variables.$padding-4 variables.$padding-12;
    font-weight: 700;
    color: colors.$grey-violet-700;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: colors.$secondary-500-base;
    text-shadow: none;
    background: colors.$secondary-050;
    border-radius: 32px;

    & path {
      fill: colors.$secondary-500-base;
    }
  }

  .ant-tabs-ink-bar {
    background: colors.$secondary-500-base;
  }

  .ant-tabs-nav:before {
    display: none;
  }
}
