@use 'colors.scss';

.antd-overwrite {
  .ant-switch {
    background-color: colors.$primary-100;
    border-radius: 100px;

    &:hover,
    &:focus {
      background-color: colors.$primary-200;
    }

    &.ant-switch-checked {
      background-color: colors.$secondary-500-base;
    }

    &.ant-switch-disabled {
      background-color: colors.$grey-warm-100;

      &:hover {
        background-color: colors.$grey-warm-100;
      }
    }
  }
}
