@use 'colors.scss';
@use 'variables';
@use 'typography';

.antd-overwrite {
  .ant-select {
    color: colors.$grey-warm-600;
    font-size: 12px;

    &.ant-select-lg {
      .ant-select-selector {
        font-size: 12px !important;
      }
    }

    &-selector {
      border: 1px solid colors.$grey-violet-200 !important;
      background-color: colors.$grey-violet-050 !important;
      border-radius: 8px !important;

      &:hover {
        box-shadow: 0 0 0 2px rgba(colors.$primary-500-base, 0.6) !important;
      }

      &:focus,
      &:focus-within {
        box-shadow: 0 0 0 2px rgba(colors.$primary-500-base, 1) !important;
      }
    }

    &-auto-complete {
      .ant-select-selector:hover {
        box-shadow: none !important;
      }
      .ant-select-selector:focus-within {
        box-shadow: none !important;
      }
    }

    &-selection {
      &-placeholder {
        color: colors.$grey-warm-400;
      }
    }

    &-item-option-active {
      background: colors.$grey-violet-050 !important;
    }

    &-item-option-selected {
      background: colors.$grey-violet-200 !important;
      font-weight: 700 !important;
    }

    &-multiple &-focused {
      .ant-select-selector {
        box-shadow: 0 0 0 2px rgba(colors.$primary-500-base, 1) !important;
      }
    }
  }
}

.antd-overwrite {
  .ant-form-item-has-error {
    .ant-select {
      &-selector {
        &:hover {
          box-shadow: 0 0 0 2px rgba(colors.$danger-400, 0.6) !important;
        }

        &:focus,
        &:focus-within {
          box-shadow: 0 0 0 2px rgba(colors.$danger-400, 1) !important;
        }
      }
    }
  }
}
