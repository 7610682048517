@use 'colors.scss';

html,
body {
    padding: 0;
    margin: 0;
    font-family: 'Comfortaa', serif;
    background-color: colors.$grey-violet-050;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}
