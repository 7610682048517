$screen-xs: 480px;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;

$padding-4: 4px;
$padding-8: 8px;
$padding-12: 12px;
$padding-16: 16px;
$padding-20: 20px;
$padding-24: 24px;
$padding-28: 28px;
$padding-32: 32px;
$padding-36: 36px;
$padding-40: 40px;
$padding-56: 56px;
$padding-60: 60px;
$padding-64: 64px;

$margin-4: 4px;
$margin-8: 8px;
$margin-12: 12px;
$margin-16: 16px;
$margin-20: 20px;
$margin-24: 24px;
$margin-28: 28px;
$margin-32: 32px;
$margin-36: 36px;
$margin-40: 40px;
$margin-48: 48px;
$margin-56: 56px;
$margin-60: 60px;
$margin-64: 64px;

// header height
$header-height-mobile-nav: 71px;
$header-height-mobile-link: 38px;
$header-height-mobile: calc($header-height-mobile-link + $header-height-mobile-nav);
$header-height-desktop: 60px;

// bottom height
$bottom-height-mobile: 60px;

// animations
$animate-5s: 5s ease;
$animate-2s: 2s ease;