@use 'colors.scss';
@use 'variables';

.antd-overwrite {
  .ant-table {
    margin-bottom: 32px;
  }
  .ant-table-thead > tr > th {
    font-family: 'Cera Pro', serif;
    font-style: normal;
    font-weight: bold;
    line-height: 15px;
    text-transform: uppercase;
    color: colors.$primary-200;
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid colors.$primary-050;
    font-size: 14px;
    line-height: 18px;
    padding: variables.$padding-20 variables.$padding-24;
  }

  .ant-table, .ant-table > table {
    border-radius: 8px 8px 0 0;
  }
}

.small-table {
  .ant-table-tbody > tr > td:hover {
    background-color: #EFECFE;
    color: colors.$secondary-500-base;
    font-family: 'Cera Pro', serif;
  }
}

.ant-table-expanded-row-fixed {
  cursor: auto;
}

.ant-table-header {
  border-radius: 0 !important;
}
