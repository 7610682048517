@use 'colors';
@use 'typography';
@use 'variables';

.ui-alert {
  &.ant-alert {
    width: 100%;
    background-color: colors.$white;
    border-radius: 4px;
    padding: 8px 8px 8px 16px;

    .ant-alert-message {
      @extend .main-body-text;
      color: colors.$grey-warm-600;
    }

    .ant-alert-icon {
      width: 36px;
      height: 36px;
      margin-right: 12px;
    }

    .ant-alert-content {
      text-align: left;
    }

    .ant-alert-close-icon {
      align-self: flex-start;
    }

    &.ant-alert-success {
      border: 1px solid colors.$success-400;
    }

    &.ant-alert-error {
      border: 1px solid colors.$secondary-400;
    }

    &.ant-alert-warning {
      border: 1px solid colors.$warning-400;
    }

    &.ant-alert-info {
      border: 1px solid colors.$info-400;
    }
  }
}
