.modalButtons {
  width: 100%;
  .modalCreateButton {
    width: 100%;
  }
  .modalGoToButton {
    width: 100%;
    margin-top: 8px !important;
  }
}
