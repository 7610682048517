@use '../../../styles/variables.scss';
@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.header {
  top: 0;
  z-index: 5;
  border-bottom: 1px solid colors.$grey-violet-300;
}

.userSwitch {
  display: block;
  @include mixins.desktop-medium {
    display: none;
  }
}
