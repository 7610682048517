@use 'colors.scss';
@use 'alert.scss';
@use './select';
@use './typography';
@use './inputs';
@use 'datepicker.scss';
@use 'checkboxes.scss';
@use './radios';
@use './switches';
@use 'notifications.scss';
@use 'collapse.scss';
@use './modal';
@use './tabs';
@use './pagination';
@use 'buttons.scss';
@use './table';

a {
  color: colors.$secondary-500-base;
  @extend .h5;
  &:hover {
    color: colors.$secondary-600;
    text-decoration: underline;
  }
}
body {
  margin: 0;
  font-family: Source Sans Pro, serif, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: colors.$grey-warm-600;
}

code {
  font-family: Source Sans Pro, serif, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText {
  display: none;
}
