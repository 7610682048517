@use 'colors.scss';
@use 'typography';

.ant-input-overrides {
  padding: 20px 24px;
  border: 1px solid colors.$grey-violet-200;
  border-radius: 8px;
  background-color: colors.$grey-violet-050;
  @extend .main-body-text;
  color: colors.$grey-warm-600;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    /* Chrome Autocomplete */
    -webkit-box-shadow: 0 0 0 1000px colors.$grey-violet-050 inset;
  }

  &:hover {
    box-shadow: 0 0 0 2px colors.$primary-300;
    border: 1px solid colors.$grey-violet-200;
  }

  &:focus,
  &:active {
    background-color: colors.$white;
    box-shadow: 0 0 0 2px colors.$primary-500-base;
    border: 1px solid colors.$grey-violet-200;
    color: colors.$primary-500-base;
  }

  &[disabled] {
    &:hover {
      border: 1px solid colors.$grey-warm-100 !important;
    }

    color: colors.$grey-warm-200;
    background-color: colors.$grey-warm-050;
    border: 1px solid colors.$grey-warm-100;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: colors.$grey-warm-200;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: colors.$grey-warm-200;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: colors.$grey-warm-200;
    }

    box-shadow: none;
  }

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: colors.$grey-warm-400;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: colors.$grey-warm-400;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: colors.$grey-warm-400;
  }

  &.ant-input-lg {
    padding: 24px 32px;
    @extend .large-body-text;
  }

  &.ant-input-sm {
    padding: 12px 16px;
    @extend .small-body-text;
  }
}

.antd-overwrite {
  .ant-input {
    @extend .ant-input-overrides;
  }
  
  .ant-input-number {
    @extend .ant-input-overrides;
    width: 100%;
  }

  .ant-form {
    .ant-form-item {
      margin-bottom: 28px;

      &-explain,
      &-extra {
        @extend .small-body-text;
        margin-top: 4px;
        min-height: 24px;
        color: colors.$danger-400;
        transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1); // sync input color transition
      }

      &-with-help {
        margin-bottom: 0;
      }

      .ant-form-item-label {
        /**
         * This solves an issue that exists on firefox for form verticals.
         * Tested also on Chrome and safari
         */
        flex: unset;

        & > label {
          @extend .h6;
          color: colors.$grey-warm-600;
        }
      }

      .ant-form-item-control {
        flex: unset;
      }

      &.ant-form-item-has-error {
        .ant-input-affix-wrapper {
          background-color: colors.$grey-violet-050;

          .ant-input-suffix {
            color: colors.$danger-400;

            span {
              color: colors.$danger-400;
            }
          }

          &.ant-input-affix-wrapper-focused {
            background-color: colors.$white;
          }
        }

        .ant-input {
          color: colors.$danger-400;

          &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: colors.$danger-300;
            opacity: 1; /* Firefox */
          }

          &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: colors.$danger-300;
          }

          &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: colors.$danger-300;
          }
        }

        &:hover {
          .ant-input {
            box-shadow: 0 0 0 2px colors.$danger-400;

            &::placeholder {
              /* Chrome, Firefox, Opera, Safari 10.1+ */
              color: colors.$danger-400;
              opacity: 1; /* Firefox */
            }

            &:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: colors.$danger-400;
            }

            &::-ms-input-placeholder {
              /* Microsoft Edge */
              color: colors.$danger-400;
            }
          }

          .ant-input-affix-wrapper {
            &:hover {
              box-shadow: 0 0 0 2px colors.$danger-400;
            }

            .ant-input {
              box-shadow: none;
            }
          }
        }

        .ant-form-item-control {
          .ant-input {
            border-color: colors.$danger-400;
            background-color: colors.$grey-violet-050;

            &:active,
            &:focus {
              background-color: colors.$white;
              box-shadow: 0 0 0 2px colors.$danger-400;
              color: colors.$primary-500-base;
            }
          }

          .ant-form-item-explain-error {
            color: colors.$danger-400;
            @extend .small-body-text;
          }

          .ant-input-affix-wrapper {
            .ant-input {
              background-color: transparent;

              &:active,
              &:focus {
                box-shadow: none;
                color: colors.$primary-500-base;
              }
            }
          }
        }
      }
    }
  }

  .ant-input-affix-wrapper {
    border-radius: 8px;
    border: 1px solid colors.$grey-violet-200;
    background-color: colors.$grey-violet-050;

    .ant-input-suffix {
      color: colors.$primary-300;

      span {
        color: colors.$primary-300;
      }
    }

    .ant-input-prefix {
      margin-right: 20px;
    }

    .ant-input {
      background-color: transparent;
      border-radius: 0;
      padding: 0;

      &:hover,
      &:focus,
      &:active {
        border: none;
        box-shadow: none;
      }

      &[disabled] {
        &:hover {
          border: none !important;
        }
      }
    }

    &:hover {
      box-shadow: 0 0 0 2px colors.$primary-300;
      border: 1px solid colors.$grey-violet-200 !important;
    }

    &.ant-input-affix-wrapper-disabled {
      .ant-input-suffix {
        color: colors.$grey-warm-200;

        span {
          color: colors.$grey-warm-200;
        }
      }

      color: colors.$grey-warm-200;
      background-color: colors.$grey-warm-050;
      border: 1px solid colors.$grey-warm-100;

      &:hover {
        border: 1px solid colors.$grey-warm-100 !important;
        box-shadow: none;

        .ant-input-suffix {
          color: colors.$grey-warm-200;

          span {
            color: colors.$grey-warm-200;
          }
        }
      }
    }

    &.ant-input-affix-wrapper-focused {
      background-color: colors.$white;
      box-shadow: 0 0 0 2px colors.$primary-500-base;
      border: 1px solid colors.$grey-violet-200;
      color: colors.$primary-500-base;

      .ant-input-suffix {
        color: colors.$primary-500-base;

        span {
          color: colors.$primary-500-base;
        }
      }
    }

    padding: 20px 24px;

    &.ant-input-affix-wrapper-lg {
      padding: 24px 32px;
    }

    &.ant-input-affix-wrapper-sm {
      padding: 12px 16px;
    }
  }

  .ant-select-item-group {
    @extend .h6;
    color: colors.$grey-warm-600;
  }
}

#createPosition_title,
#createFirstName_firstName,
#createFirstName_customLink,
#createFirstName_email {
  margin-bottom: 20px;
}
